<template>
  <modal
    v-if="showModal"
    :show="showModal"
    modal-classes="modal-secondary"
    size="lg"
  >
    <form class="" @submit.prevent="">
      <!-- <base-input
        :label="`${$t('PURCHASES_ORDERS.ITEM_TYPE')}`"
        v-if="!purchasesOrderItem.id"
      >
        <el-select
          name="Type"
          v-model="purchasesOrderItemModel.purchasesOrderable.type"
          prepend-icon="fas fa-calendar"
          @change="changePurchasesOrderableType"
        >
          <el-option :value="null" :label="$t('COMMON.NONE')" />
          <el-option
            v-if="purchasesOrder.organization"
            value="products"
            :label="$t('COMMON.PRODUCT')"
          />
          <el-option
            v-if="
              !$currentUserIsLevelOrganization() && !purchasesOrder.organization
            "
            value="packages"
            :label="$t('COMMON.PACKAGE')"
          />
        </el-select>
      </base-input>
      <validation-error :errors="apiValidationErrors.purchasesOrderable" /> -->

      <div>
        <base-input
          v-if="
            purchasesOrderItemModel.purchasesOrderable.type.includes('products')
          "
          :label="`${$t(`COMMON.PRODUCT`)} (*)`"
        >
          <product-selector
            :filterOrganization="purchasesOrder.organization.id"
            :productObject="purchasesOrderItemModel.purchasesOrderable"
            :allowNone="false"
            :showAll="false"
            :filterWarehouse="
              purchasesOrder.destinationWarehouse
                ? purchasesOrder.destinationWarehouse.id
                : null
            "
            :filterSupplier="
              purchasesOrder.issuer ? purchasesOrder.issuer.id : null
            "
            @productChanged="productChanged"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.purchasesOrderable" />
      </div>

      <base-input
        :label="`${$t('COMMON.CODE')} (*)`"
        :placeholder="$t('COMMON.SEARCH')"
        v-model="purchasesOrderItemModel.code"
        input-classes="form-control-alternative"
        v-if="!purchasesOrderItemModel.purchasesOrderable.type"
      >
      </base-input>
      <validation-error
        v-if="!purchasesOrderItemModel.purchasesOrderable.type"
        :errors="apiValidationErrors.code"
      />

      <base-input
        :label="$t('COMMON.EXCERPT')"
        :placeholder="$t('COMMON.EXCERPT')"
        input-classes="form-control-alternative"
      >
        <html-editor
          v-model="purchasesOrderItemModel.excerpt"
          @change="onFormChanged()"
        >
        </html-editor>
      </base-input>
      <validation-error :errors="apiValidationErrors.excerpt" />

      <base-input
        :label="`${$t('COMMON.UNIT_PRICE')} (*)`"
        :placeholder="$t('COMMON.UNIT_PRICE')"
        v-model="purchasesOrderItemModel.unit_price"
        type="number"
        step="0.0001"
        input-classes="form-control-alternative"
      >
      </base-input>
      <validation-error :errors="apiValidationErrors.unit_price" />

      <base-input
        :label="`${$t('COMMON.QUANTITY')} (*)`"
        :placeholder="$t('COMMON.QUANTITY')"
        v-model="purchasesOrderItemModel.quantity"
        type="number"
        step="1"
        input-classes="form-control-alternative"
      >
      </base-input>
      <validation-error :errors="apiValidationErrors.quantity" />

      <taxes-selector
        :label="$t('COMMON.TAXES')"
        :taxes="purchasesOrderItemModel.taxes"
        @taxesChanged="(taxes) => (purchasesOrderItemModel.taxes = taxes)"
      />
      <validation-error :errors="apiValidationErrors.taxes" />

      <base-input
        :label="$t('COMMON.DISCOUNT_PERCENT')"
        :placeholder="$t('COMMON.DISCOUNT_PERCENT')"
        v-model="purchasesOrderItemModel.discount"
        type="number"
        step="0.0001"
        input-classes="form-control-alternative"
      >
      </base-input>
      <validation-error :errors="apiValidationErrors.discount" />
    </form>

    <template slot="footer">
      <button
        v-if="!purchasesOrderItemModel.id"
        type="submit"
        class="btn btn-primary"
        @click="submitPurchasesOrderItemForm"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("PURCHASES_ORDERS.ADD_PURCHASES_ORDER_ITEM") }}
      </button>

      <base-button
        v-if="purchasesOrderItemModel.id"
        native-type="submit"
        type="primary"
        class="new-purchasesOrder-item--add"
        @click="submitPurchasesOrderItemForm"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("PURCHASES_ORDERS.UPDATE_PURCHASES_ORDER_ITEM") }}
      </base-button>

      <base-button
        v-if="purchasesOrderItemModel.id"
        type="danger"
        @click="deletePurchasesOrderItem(purchasesOrderItemModel.id)"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("PURCHASES_ORDERS.DELETE_PURCHASES_ORDER_ITEM") }}
      </base-button>

      <button
        type="button"
        class="btn btn-link ml-auto"
        @click="closePurchasesOrderItemModal"
        :disabled="loading"
      >
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import {
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { cloneDeep } from "lodash";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import TaxesSelector from "@/components/TaxesSelector.vue";
import ProductSelector from "@/components/ProductSelector.vue";
import ValidationError from "@/components/ValidationError.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import defaultPurchasesOrderItem from "../defaultPurchasesOrderItem";

export default {
  name: "purchasesOrder-view-details-purchasesOrder-item-form",

  components: {
    HtmlEditor,
    ValidationError,
    TaxesSelector,
    ProductSelector,
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [formMixin, requestErrorMixin],

  props: [
    "purchasesOrder",
    "purchasesOrderItem",
    "showModal",
    "loading",
    "formErrors",
  ],

  data() {
    return {
      purchasesOrderItemModel: cloneDeep(defaultPurchasesOrderItem),
    };
  },

  computed: {},

  methods: {
    closePurchasesOrderItemModal() {
      this.$emit("onClosePurchasesOrderItemModal", true);
    },

    submitPurchasesOrderItemForm() {
      const purchasesOrderItemData = cloneDeep(this.purchasesOrderItemModel);
      purchasesOrderItemData.unit_price = parseFloat(
        purchasesOrderItemData.unit_price
      );
      purchasesOrderItemData.quantity = parseInt(
        purchasesOrderItemData.quantity,
        10
      );
      purchasesOrderItemData.discount = parseFloat(
        purchasesOrderItemData.discount
      );
      if (!purchasesOrderItemData.purchasesOrderable.id) {
        purchasesOrderItemData.purchasesOrderable = null;
      }

      this.$emit("onSubmitPurchasesOrderItemForm", purchasesOrderItemData);
    },

    deletePurchasesOrderItem(purchasesOrderItemId) {
      this.$emit("onDeletePurchasesOrderItem", purchasesOrderItemId);
    },

    productChanged(productId, product) {
      if (product.warehouseProduct) {
        this.purchasesOrderItemModel.purchasesOrderable = {
          type: "warehouse-products",
          id: product.warehouseProduct.id.toString(),
        };
        this.purchasesOrderItemModel.unit_price =
          product.warehouseProduct.buying_price;
        this.purchasesOrderItemModel.code =
          product.warehouseProduct.sku || product.sku || product.code;
        this.purchasesOrderItemModel.excerpt = product.denomination;
        this.purchasesOrderItemModel.taxes = product.warehouseProduct.taxes;
      } else if (product.supplierProduct) {
        this.purchasesOrderItemModel.purchasesOrderable = {
          type: "supplier-products",
          id: product.supplierProduct.id.toString(),
        };
        this.purchasesOrderItemModel.unit_price = product.supplierProduct.price;
        this.purchasesOrderItemModel.code =
          product.supplierProduct.sku || product.sku || product.code;
        this.purchasesOrderItemModel.excerpt = product.denomination;
        this.purchasesOrderItemModel.taxes = product.supplierProduct.taxes;
      } else {
        this.purchasesOrderItemModel.purchasesOrderable.id = productId;
        this.purchasesOrderItemModel.unit_price = product.price;
        this.purchasesOrderItemModel.code = product.code;
        this.purchasesOrderItemModel.excerpt = product.denomination;
        this.purchasesOrderItemModel.taxes = product.taxes;
      }
      this.purchasesOrderItemModel.purchasesOrderable.product_id = product.id;
    },
  },

  mounted() {},

  watch: {
    purchasesOrderItem(purchasesOrderItem) {
      if (purchasesOrderItem) {
        this.purchasesOrderItemModel = cloneDeep(purchasesOrderItem);
      }
      this.resetApiValidation();
    },

    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
  },
};
</script>
