import moment from "moment";

export default {
  type: "purchases-orders",
  expiration_time: moment().add(30, "days").format("YYYY-MM-DD"),
  code: null,
  excerpt: "",
  discounts: [],
  deliveryItemsState: {
    orderedItems: {},
    deliveredItems: {},
    remainingItems: {},
    orderDelivered: false,
  },
  invoicingItemsState: {
    orderedItems: {},
    deliveredItems: {},
    remainingItems: {},
    orderInvoiced: false,
  },
  relationshipNames: [
    "issuer",
    "organization",
    "allowedLocations",
    "purchasesInvoice",
    "destinationWarehouse",
  ],
  issuer: {
    type: "suppliers",
    id: null,
  },
  organization: {
    type: "organizations",
    id: null,
  },
  purchasesInvoice: {
    type: "purchases-invoices",
    id: null,
  },
  destinationWarehouse: {
    type: "warehouses",
    id: null,
  },
  items: [],
  allowedLocations: [],
};
