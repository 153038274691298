<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">
      {{ $t("COMMON.PURCHASES_DELIVERIES") }}
    </h3>
    <purchases-delivery-list-table :filterPurchasesOrder="purchasesOrder.id" />
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";
import PurchasesDeliveryListTable from "../../PurchasesDeliveryManagement/partials/PurchasesDeliveryListTable.vue";

export default {
  name: "purchases-order-view-deliveries",

  components: {
    PurchasesDeliveryListTable,
  },

  mixins: [formMixin],

  props: ["purchasesOrder"],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {},
};
</script>
<style scoped>
.delivery-item-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
