<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">
      {{ `${purchasesOrder.code}` }} - {{ $t("COMMON.LOGS") }}
    </h3>
    <log-list-table :filterSubject="purchasesOrder" />
  </div>
</template>
<script>
import LogListTable from "@/views/Pages/DefaultModule/LogManagement/partials/LogListTable.vue";

export default {
  name: "purchasesOrder-view-logs",

  components: { LogListTable },

  props: ["purchasesOrder"],

  mixins: [],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {
    user(user) {},
  },
};
</script>
