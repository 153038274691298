<template>
  <div class="container-fluid">
    <purchases-order-form
      :loading="loading"
      :purchasesOrderData="purchasesOrder"
      :formErrors="formErrors"
      @purchasesOrderSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import defaultTaxes from "@/constants/defaultTaxes";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultPurchasesOrder from "../defaultPurchasesOrder";
import PurchasesOrderForm from "../partials/PurchasesOrderForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    PurchasesOrderForm,
  },

  mixins: [alertLeave],

  data() {
    const purchasesOrder = cloneDeep(defaultPurchasesOrder);
    purchasesOrder.taxes = cloneDeep(defaultTaxes);
    if (this.$currentUserIsLevelOrganization()) {
      purchasesOrder.organization = {
        type: "organizations",
        id: this.$currentUserOrganizationId(),
      };
    }
    return {
      purchasesOrder: purchasesOrder,
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(purchasesOrder) {
      this.loading = true;
      const purchasesOrderData = cloneDeep(purchasesOrder);
      try {
        await this.$store.dispatch("purchasesOrders/add", purchasesOrderData);
        this.$notify({
          type: "success",
          message: this.$t("PURCHASES_ORDERS.PURCHASES_ORDER_ADDED"),
        });
        const purchasesOrder = await this.$store.getters[
          "purchasesOrders/purchasesOrder"
        ];
        this.$emit("onViewPurchasesOrder", purchasesOrder, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
