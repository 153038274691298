<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div
      class="form-wrapper"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
    >
      <base-input
        :label="`${$t('COMMON.ORGANIZATION')} (*)`"
        :placeholder="$t('COMMON.ORGANIZATION')"
      >
        <organization-selector
          :allowNone="true"
          :organization="purchasesOrder.organization.id"
          :filterable="true"
          :showAll="false"
          :disabled="!!purchasesOrder.id"
          @organizationChanged="
            (organizationId) => {
              purchasesOrder.organization.id = organizationId;
              purchasesOrder.allowedLocations = [];
              purchasesOrder.issuer.id = null;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.organization" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('COMMON.LOCATION')}`"
        :placeholder="$t('COMMON.LOCATION')"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
      >
        <locations-selector
          :locations="purchasesOrder.allowedLocations"
          :filterable="true"
          :showAll="false"
          :multiple="true"
          :organization="purchasesOrder.organization.id"
          @locationsChanged="
            (locations) => {
              purchasesOrder.allowedLocations = locations;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.location" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('PURCHASES_ORDERS.EXPIRATION_TIME')} (*)`"
        :placeholder="$t('PURCHASES_ORDERS.EXPIRATION_TIME')"
      >
        <flat-picker
          :config="{
            allowInput: true,
            minDate: 'today',
            locale: $flatPickrLocale(),
          }"
          class="form-control datepicker"
          v-model="purchasesOrder.expiration_time"
          @on-change="
            () => {
              onFormChanged();
            }
          "
        >
        </flat-picker>
      </base-input>
      <validation-error :errors="apiValidationErrors.expiration_time" />
    </div>

    <div class="form-wrapper">
      <base-input :label="`${$t(`PURCHASES_ORDERS.SELECT_SUPPLIERS`)} (*)`">
        <supplier-selector
          :allowNone="true"
          :supplier="purchasesOrder.issuer.id"
          :filterable="true"
          :showAll="false"
          :filterOrganization="purchasesOrder.organization.id"
          :disabled="!!purchasesOrder.id"
          @supplierChanged="
            (supplierId) => {
              purchasesOrder.issuer.id = supplierId;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.issuer" />
    </div>

    <div class="form-wrapper">
      <base-input :label="`${$t(`COMMON.DESTINATION_WAREHOUSE`)}`">
        <warehouse-selector
          :allowNone="true"
          :warehouse="
            purchasesOrder.destinationWarehouse
              ? purchasesOrder.destinationWarehouse.id
              : null
          "
          :filterable="true"
          :showAll="false"
          :filterOrganization="purchasesOrder.organization.id"
          :disabled="!!purchasesOrder.id"
          @warehouseChanged="
            (warehouseId) => {
              purchasesOrder.destinationWarehouse.id = warehouseId;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.destinationWarehouse" />
    </div>

    <h2 class="col-12 mt-0 mb-0">{{ $t("COMMON.NOTE") }}</h2>

    <div class="form-wrapper full">
      <html-editor v-model="purchasesOrder.excerpt" @change="onFormChanged()">
      </html-editor>
      <validation-error :errors="apiValidationErrors.excerpt" />
    </div>

    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
        {{
          purchasesOrder.id
            ? $t("PURCHASES_ORDERS.EDIT_PURCHASES_ORDER")
            : $t("PURCHASES_ORDERS.ADD_PURCHASES_ORDER")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import moment from "moment";
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import SupplierSelector from "@/components/SupplierSelector.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import WarehouseSelector from "@/components/WarehouseSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    LocationsSelector,
    flatPicker,
    HtmlEditor,
    SupplierSelector,
    WarehouseSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["purchasesOrderData", "formErrors", "loading"],

  data() {
    let purchasesOrderData = { ...this.purchasesOrderData };
    purchasesOrderData = this.$fillUserOrganizationData(purchasesOrderData);

    return {
      purchasesOrder: purchasesOrderData,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let purchasesOrderData = cloneDeep(this.purchasesOrder);
      purchasesOrderData.expiration_time = moment(
        purchasesOrderData.expiration_time
      ).toISOString();
      purchasesOrderData = this.$fillUserOrganizationData(purchasesOrderData);
      delete purchasesOrderData.purchasesInvoice;
      if (purchasesOrderData.destinationWarehouse) {
        if (!purchasesOrderData.destinationWarehouse.id) {
          delete purchasesOrderData.destinationWarehouse;
        }
      }
      this.$emit("purchasesOrderSubmitted", purchasesOrderData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    purchasesOrderData(purchasesOrderData) {
      if (purchasesOrderData) {
        this.purchasesOrder = {
          ...this.purchasesOrder,
          ...cloneDeep(purchasesOrderData),
        };
        if (!this.purchasesOrder.organization) {
          this.purchasesOrder.organization = {
            type: "organizations",
            id: null,
          };
        }
      }
    },
  },
};
</script>
