import defaultTaxes from "@/constants/defaultTaxes";

export default {
  type: "purchases-order-items",
  code: null,
  excerpt: "",
  unit_price: null,
  quantity: 1,
  discount: 0,
  relationshipNames: ["purchasesOrder", "purchasesOrderable"],
  purchasesOrder: {
    type: "purchases-orders",
    id: null,
  },
  purchasesOrderable: {
    type: "products",
    id: null,
  },
  pricing: {},
  taxes: defaultTaxes,
};
