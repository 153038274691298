<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">
      {{ $t("COMMON.PURCHASES_INVOICES") }}
    </h3>

    <purchases-invoice-list-table :filterPurchasesOrder="purchasesOrder.id" />
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";
import PurchasesInvoiceListTable from "../../PurchasesInvoiceManagement/partials/PurchasesInvoiceListTable.vue";

export default {
  name: "purchases-order-view-invoices",

  components: {
    PurchasesInvoiceListTable,
  },

  mixins: [formMixin],

  props: ["purchasesOrder"],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {},
};
</script>
<style scoped>
.invoice-item-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
